<template>
  <div :id="id" style="height: 100%, width: 100%">
    <b-modal v-model="modalShow" size="ls" @shown="initModal" :title="labelTitle" footer-class="footerClass"
      @hidden="hidden"
      content-class="shadow" no-close-on-backdrop
    >
      <b-alert dismissible fade :show="showError" variant="success" @dismissed="dismissAlert">
        <font-awesome-icon :icon="['far', 'check']"/>
        &nbsp;&nbsp;{{ alertMsg }}
      </b-alert>

      <ag-grid-vue style="width: 100%;" class="ag-theme-balham folder-selector-grid-height" id="folderSelectorGrid"
            :gridOptions="folderGridOptions"
            @grid-ready="onFolderGridReady"
            :autoGroupColumnDef="autoFolderGroupColumnDef"
            :columnDefs="[]"
            :overlayLoadingTemplate="overlayLoadingTemplate"
            :defaultColDef="defaultColDef"
            :getRowId="params => { return params.data.uuId }"
            :rowData="rowData"
            rowModelType="serverSide"
            :serverSideInfiniteScroll="true"
            suppressContextMenu
            suppressMultiSort
            treeData
            >
     </ag-grid-vue>

      <template v-slot:modal-footer="{ cancel }">
        <b-button :disabled="disableOk" size="sm" variant="success" @click="ok">{{ $i18n.t('button.ok') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('button.cancel') }}</b-button>
      </template>
    </b-modal>
    
  </div>
</template>

<script>
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import { cloneDeep } from 'lodash';
import { strRandom } from '@/helpers';

import { folderService } from '@/services';
import FolderLabel from '@/components/Aggrid/CellRenderer/FolderLabel.vue';

function ServerSideFolderDatasource(self, func, api) {
  const gridApi = api;
  return {
    getRows(params) {
      const groupKeys = params.request.groupKeys;
      let currentId = groupKeys.length !== 0 ? groupKeys[groupKeys.length - 1] : null;
      
      // fake root node
      if (currentId === null) {
        const rows = [{uuId: '-1', name: '/', folders: []}];
        params.successCallback(rows, rows.length);
        const rowNode = gridApi.getRowNode('-1');
        if (rowNode !== null) {
          if (self.path !== null && typeof self.path['-1'] !== 'undefined') {
            delete self.path['-1'];
            rowNode.setExpanded(true);
            rowNode.setSelected(true);
            self.selectedFolder.splice(0, self.selectedFolder.length, {uuId: rowNode.data.uuId, name: rowNode.data.name});
          }
        }
        return;
      }
      
      // handling for getting root node values
      if (currentId === '-1') {
        currentId = null;
      }
      
      func(currentId).then((response) => {  
        const data = response.data;
        const rows = typeof data[data.jobCase].folders !== 'undefined' ? data[data.jobCase].folders : [];
        
        const order = "asc";
        rows.sort(function(a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return order === "asc" ? -1 : 1;
          }
          else if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return order === "asc" ? 1 : -1;
          }
          return 0;
        });
        params.successCallback(rows, rows.length);
        
        // Update node to indicate if it has no children
        const hasNoSubFolderIds = rows.filter(i => !i.hasSubFolder).map(i => i.uuId);
        hasNoSubFolderIds.forEach(i => {
          const rowNode = gridApi.getRowNode(i);
          if(rowNode !== null) {
            rowNode.group = false;
          }
        });
        
        // expand the added child nodes if they were previously selected
        for (let idx = 0, len = rows.length; idx < len; idx++) {
          const rowId = rows[idx].uuId;
          const rowNode = gridApi.getRowNode(rowId);
          if (rowNode !== null) {
            if (self.path !== null && typeof self.path[rowId] !== 'undefined') {
              delete self.path[rowId];
              rowNode.setExpanded(true);
              rowNode.setSelected(true);
              gridApi.ensureNodeVisible(rowNode, 'middle');
              self.selectedFolder.splice(0, self.selectedFolder.length, {uuId: rowNode.data.uuId, name: rowNode.data.name});
            }
          }
        }
      })
      .catch(() => {
        params.successCallback([], self.totalRecords);
        gridApi.showNoRowsOverlay();
      });
    }
  }
}

export default {
  name: 'FolderSelectorModal',
  components: {
    'ag-grid-vue': AgGridVue,
    'folderLabel': FolderLabel // eslint-disable-line vue/no-unused-components
  },
  props: {
    title: { 
      type: String,
      default: null
    },
    show: {
      type: Boolean,
      required: true
    },
    currentPath: {
      type: Object,
      default: null
    }
  },
  data: function() {
    return {
      permissionName: 'STORAGE_FILE',
      id: `FOLDER_LIST_${strRandom(5)}`,
      folderGridOptions: null,
      fileGridOptions: null,
      columnApi: null,
      folderGridApi: null,
      autoFolderGroupColumnDef: null,
      defaultColDef: null,
      rowData: null,
  
      modalShow: false,
      selectedFolder: [],

      folderId: null,
      selectedFolderName: null,
      alertMsg: null,
      path: []
    };
  },
  beforeMount() {
    this.folderGridOptions = {
      rowSelection: 'single',
      
      // indicate if row is a group node
      isServerSideGroup: function (/*dataItem*/) {
          return true;
      },
  
      // specify which group key to use
      getServerSideGroupKey: function (dataItem) {
          return dataItem.uuId;
      }
    };

    this.defaultColDef = {
      sortable: true,
      resizable: true,
      minWidth: 100,
      hide: true,
      menuTabs: []
    };

    this.autoFolderGroupColumnDef = {
      headerName: this.$t('folder.field.folder'),
      field: 'name',
      cellRendererParams: { 
        checkbox: true,
        innerRenderer: 'folderLabel',
        suppressCount: true
      }
    };
  },
  mounted() {
    this.$nextTick(()=>{
      window.addEventListener('orientationchange', this.detectOrientationChange);
    });

    const self = this;
    this.folderGridOptions.onRowClicked = function(event) {
      self.folderSelectionChanged(event);
    }

    this.folderGridOptions.onColumnVisible = function(event) {
      event.api.sizeColumnsToFit();
    }
  },
  create() {
    this.updateModalShow(this.show);
  },
  beforeDestroy() {
    window.removeEventListener('orientationchange', this.detectOrientationChange);
  },
  watch: {
    show(newValue) {
      this.updateModalShow(newValue);
    }
  },
  computed: {
    showError() {
      return this.alertMsg != null;
    },
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$i18n.t('error.grid_data_loading') }</span>`;
    },
    overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('dataview.grid.loading') }</span>`;
    },
    disableOk() {
      return this.selectedFolder.length < 1;
    },
    labelTitle() {
      return this.title? this.title : this.$t('folder.title_selector');
    }
  },
  methods: {
    folderSelectionChanged(event) {
      const self = this;
      self.selectedFolder = typeof event.data !== 'undefined' ? [{uuId: event.data.uuId, name: event.data.name}] : [];
      let rowNode = self.selectedFolder.length !== 0 ? self.folderGridApi.getRowNode(self.selectedFolder[0].uuId) : null;
      if(rowNode) {
        rowNode.setSelected(true);
      }
    },
    onFolderGridReady(params) {
      this.folderGridApi = this.folderGridOptions.api;
      this.folderGridColumnApi = this.folderGridOptions.columnApi;

      const self = this;
      const updateData = () => {
        params.api.setServerSideDatasource(new ServerSideFolderDatasource(self, folderService.get, self.folderGridApi));
      };
      updateData();
    },
    buildParams({ request: {sortModel, endRow, startRow} }) {
    
      const params = {
        start: startRow,
        limit: endRow - startRow + 1,
      };
      params.ksort = []
      params.order = []
      for(let i = 0, len = sortModel.length; i < len; i++) {
        params.ksort.push(sortModel[i].colId);
        params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
      }
      
      return params;
    },
    detectOrientationChange() {
      setTimeout(() =>{
        if(this.folderGridApi) {
          this.folderGridApi.sizeColumnsToFit();  
        }
      }, 100);
    },
    initModal() {
      this.path = cloneDeep(this.currentPath);
      this.folderGridApi.sizeColumnsToFit();
      this.reloadFolderData();
    },
    reloadFolderData() {
      this.folderGridApi.refreshServerSide({ purge: true });
    },
    dismissAlert() {
      this.alertMsg = null;
    },
    httpAjaxError(e) {
      const response = e.response;
      if (response && 403 === response.status) {
        this.alertMsg = this.$i18n.t('error.authorize_action');
        
      } else if (response && 422 === response.status) {
        this.alertMsg = this.$i18n.t('error.attention_required');
        const feedback = response.data[response.data.jobCase][0];
        this.errors.add({
          field: `file.${feedback.spot}`,
          msg: this.$i18n.t(`error.${feedback.clue}`)
        })
      } else {
        this.alertMsg = this.$i18n.t('error.internal_server');
      }
      this.scrollToTop();
    },
    scrollToTop() {
      document.querySelector(`#${this.id}`).scrollIntoView();
    },
    updateSelected(newValue) {
      this.selectedFolder.splice(0, this.selectedFolder.length, ...newValue);
    },
    ok() {
      const payload = {
        uuId: this.selectedFolder[0].uuId, 
        name: this.selectedFolder[0].name,
        color: this.selectedFolder[0].color,
        path: { '-1': true }
      }
      const rowNode = this.folderGridApi.getRowNode(payload.uuId);
      function collectPath(rNode, p) {
        if(rNode && rNode.level > -1) {
          p[rNode.id] = true;
          collectPath(rNode.parent, p);
        }
      }
      collectPath(rowNode, payload.path);
      this.$emit('success', payload);
      this.$emit('update:show', false);
    },
    updateModalShow(newValue) {
      this.modalShow = newValue;
    },
    hidden() {
      this.$emit('update:show', false);
    },
  }
}


</script>

<style lang="scss" scroped>
.folder-selector-grid-height {
  height: 350px;
  min-height: 350px;
}
</style>