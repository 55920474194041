var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" }, attrs: { id: _vm.id } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "ls",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { shown: _vm.initModal, hidden: _vm.hidden },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: _vm.disableOk,
                        size: "sm",
                        variant: "success",
                      },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$i18n.t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                dismissible: "",
                fade: "",
                show: _vm.showError,
                variant: "success",
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", { attrs: { icon: ["far", "check"] } }),
              _vm._v("   " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c("ag-grid-vue", {
            staticClass: "ag-theme-balham folder-selector-grid-height",
            staticStyle: { width: "100%" },
            attrs: {
              id: "folderSelectorGrid",
              gridOptions: _vm.folderGridOptions,
              autoGroupColumnDef: _vm.autoFolderGroupColumnDef,
              columnDefs: [],
              overlayLoadingTemplate: _vm.overlayLoadingTemplate,
              defaultColDef: _vm.defaultColDef,
              getRowId: function (params) {
                return params.data.uuId
              },
              rowData: _vm.rowData,
              rowModelType: "serverSide",
              serverSideInfiniteScroll: true,
              suppressContextMenu: "",
              suppressMultiSort: "",
              treeData: "",
            },
            on: { "grid-ready": _vm.onFolderGridReady },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }